import { useEffect, useState,useRef } from "react"
import Button from "../../../../base-components/Button"
import { FormInline, FormInput, FormLabel, FormTextarea, InputGroup } from "../../../../base-components/Form"
import Menu from "../../../../base-components/Headless/Menu"
import Lucide from "../../../../base-components/Lucide"
import Table from "../../../../base-components/Table"
import Dialog from "../../../../base-components/Headless/Dialog"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../../stores/store"
import { useLoader } from "../../../../base-components/Loader"
import './Bill.css'; // Import the CSS file for styling
import { current } from "@reduxjs/toolkit"
import Litepicker from "../../../../base-components/Litepicker"


type Head = { date: string, customerName: string, vehicleNumber: string, mobile: string, roDate: string }
type Quotation = { EventId: number, events: Events[], charges: Additional[], foodCost: number, discount: number }
type Events = { name: string, dishes: Dish[], details: Details }
type Dish = { title: string, contents: string }
type Details = { people: number, date: string, type: string, cost: number, }
type Additional = { title: string, description: string, price: number, }

export default function Main() {
	const { showLoader, hideLoader } = useLoader()
	const { serviceId } = useParams();
	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId)

	//useEffect(() => {
	//	if (eventId == null) nav('/admin')
	//	else init()
	//}, [eventId])

	const [waNumber, setWaNumber] = useState('')
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
	const [invalidAlert, setInvalidAlert] = useState(false)
	const [newEventModalPreviewForm, setnewEventModalPreviewForm] = useState(false)
	const [newEventModalPreviewPart, setnewEventModalPreviewPart] = useState(false)
	const [newEventModalPreviewLabour, setnewEventModalPreviewLabour] = useState(false)
	const [sendWAModalOpen, setSendWAModalOpen] = useState(false)

	const [head, setHead] = useState<Head>()
	const [title, setTitle] = useState('')
	const [nextServiceDate, setNextServiceDate] = useState('')
	const [nextServiceKM, setNextServiceKM] = useState('')
	const [paidAmt, setPaidAmt] = useState('')
	//const [roDate, setRoDate] = useState('')

	const [description, setDescription] = useState('')
	const [disAmt, setDisAmt] = useState(0)
	const [totLabAmt, setTotLabAmt] = useState(0)
	const [totPartAmt, setTotPartAmt] = useState(0)
	
	const [events, setEvents] = useState<Quotation>()
	const [parts, setParts] = useState([]);
	const [text, setText] = useState('');
	const [labour, setLabour] = useState([]);
	const [currentPart, setCurrentPart] = useState({
		name: "",
		quantity: "",
		rate: "",
		amt:""
	});
	const [currentLabour, setCurrentLabour] = useState({
		name: "",
		amt: "",
		
	}); // State for the current input fields
	
	const handleAddPart = () => {
		if (!currentPart.name || !currentPart.quantity || !currentPart.rate) {
			alert("Please fill in all fields before adding a part.");
			return;
		}
		setTotPartAmt(totPartAmt + (Number(currentPart.quantity) * Number(currentPart.rate)));
		setParts([...parts, currentPart]); // Add the current part to the parts array
		setCurrentPart({ name: "", quantity: "", rate: "",amt:"" }); // Reset input fields
	};
	const handleAddLabour = () => {
		if (!currentLabour.name || !currentLabour.amt) {
			alert("Please fill in all fields before adding a part.");
			return;
		}
		setTotLabAmt(totLabAmt + Number(currentLabour.amt));
		setLabour([...labour, currentLabour]); // Add the current part to the parts array
		setCurrentLabour({ name: "", amt: "" }); // Reset input fields
	};

	const handleInputChange = (field, value) => {
		setCurrentPart({ ...currentPart, [field]: value }); // Update specific field in currentPart
	};
	const handleInputChangeLab = (field, value) => {
		setCurrentLabour({ ...currentLabour, [field]: value }); // Update specific field in currentPart
	};
	const totalAmt = () => {

		return totPartAmt + totLabAmt - disAmt;
	}
	useEffect(() => { getvehicleCustomerInfo() }, [])
	async function getvehicleCustomerInfo() {
		try {

			showLoader()
			const f = await fetch(`/api/get-bill-info/${serviceId}`)
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setHead(r.d)
					setLabour(r.b.labour)
					setParts(r.b.parts)
					setTotLabAmt(r.b.billDetails.totLabAmt)
					setTotPartAmt(r.b.billDetails.totPartAmt)
					setDisAmt(r.b.billDetails.discount)
					console.log(r.b);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const loadPdf = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation1')
			if (f.ok) {
				const d = await f.json()
				if (d.e) console.error(d)
				else window.open(d.path)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const loadPdf1 = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation')
			if (f.ok) {
				const d = await f.json()
				if (d.e) console.error(d)
				else window.open(d.path)
			} else console.error(f.status, f.statusText)
		} catch (err) { console.error('error: ', err) } finally { hideLoader() }
	}
	const showQuotation = async () => {
		try {
			showLoader()
			const cl = { serviceId: Number(serviceId), part: parts, labour: labour, totPartAmt: totPartAmt, totLabAmt: totLabAmt, totalAmt: totalAmt, disAmt: disAmt }
			const d = JSON.stringify(cl)
			console.log(d)
			const f = await fetch('/api/quotation', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else window.open(x.path)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	const sendWAQuotation = async function () {
		try {
			showLoader()
			const cl = { serviceId:Number( serviceId), part: parts, labour: labour, totPartAmt: totPartAmt, totLabAmt: totLabAmt, totalAmt: totalAmt, disAmt: disAmt, mobile: waNumber }
			const d = JSON.stringify(cl)
			console.log(d)
			const f = await fetch('/api/quotation_wa', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else { alert(x.msg) }
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const init = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation_init/' + eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) {
					alert(JSON.stringify(x))
					console.log(x)
				} else {
					setEvents(x.d.qd)
					setHead(x.d.head)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}



	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Bill</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { showQuotation() }} ><Lucide icon='PrinterCheck' className='mr-2' />Print</Button>
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { setSendWAModalOpen(true); setWaNumber(head.mobile) }} ><Lucide icon='Send' className='mr-2' />Send To WhatsApp</Button>
				<Menu className="ml-auto sm:ml-0">
					<Menu.Button as={Button} className="px-2 !box">
						<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>
					</Menu.Button>
					<Menu.Items className="w-40">
						{/*<Menu.Item onClick={loadPdf1}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export Word</Menu.Item>*/}
						<Menu.Item onClick={showQuotation}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export PDF</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</div>
		<div id='sp-q' className="mt-2 overflow-hidden intro-y box">

			<div className="bill-header border-b-2 border-gray-300 pb-4 mb-4">
				{/* Company Info */}
				<div className="company-info text-center mb-4">
					<h1 className="company-name text-lg md:text-5xl font-extrabold text-gray-800">Shree Gajanan Auto Wheel</h1>
					<hr />
					<p className="address md:text-lg text-gray-600">Kumthe Phata-Sangli Road, KAVLAPUR</p>
				</div>

				{/* Remaining Info */}
				<div className="remaining-info grid grid-cols-1 md:grid-cols-2 gap-4">
					{/* Left Section: Contact Info */}
					{head &&
						<div className="customer-info flex flex-col justify-center items-start text-left md:text-right">
							<h3 className="customer-name text-lg font-semibold text-gray-800">Customer: {head.customerName}</h3>
							<p className="customer-phone text-sm text-gray-600">Phone: {head.mobile}</p>
							<p className="vehicle-number text-sm text-gray-600">Vehicle No.: {head.vehicleNumber}</p>
							<p className="date text-sm text-gray-600">R.O. Date: {head.roDate}</p>
							<p className="date text-sm text-gray-600">Bill Date: {head.date}</p>
						</div>}
					{/* Right Section: Customer Info */}
					<div className="contact-info  text-left md:text-right">
						<p className="phone text-sm text-gray-600">Phone: 9960304741</p>
						<p className="email text-sm text-gray-600">Email: ajayjamadade198@gmail.com</p>
					</div>



				</div>
			</div>
			{/* Charges Section */}
			<div className="charges mt-8 mx-8">
				{/* Parts Charges Section */}
				<div className="parts-charges mb-8">
					<h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Parts Charges</h2>
					<div className="table w-full border-t border-gray-300 pt-4">
						<div className="table-header grid grid-cols-5 gap-4 text-sm text-gray-600 font-semibold uppercase tracking-wide border-b border-gray-200 pb-3 text-center">
							<div className="sr-no">Sr. No.</div>
							<div className="part-name">Part Name</div>
							<div className="part-quantity">Quantity</div>
							<div className="part-quantity">Rate</div>
							<div className="part-amount">Amount</div>
						</div>
						{parts.map((part, index) => (
							<div className="table-row grid grid-cols-5 gap-4 text-gray-800 py-2 border-b border-gray-200 text-center">
								<div className="sr-no">{index + 1}</div>
								<div className="part-name">{part.name}</div>
								<div className="part-quantity">{part.quantity}</div>
								<div className="part-amount">₹{part.rate}</div>
								<div className="part-amount">₹{part.amt}</div>
							</div>))}

					</div>
				</div>
				<div className="flex flex-col-reverse   sm:pb-5 sm:flex-row">
					<div className="text-center sm:text-right sm:ml-auto">

						<div className="mt-2 text-xl font-medium text-primary">
							<InputGroup>
								<InputGroup.Text>Part</InputGroup.Text>
								<FormInput type="number" placeholder="Food Cost" value={totPartAmt} disabled />
								{/*onChange={(e) => {*/}
								{/*	events.foodCost = Number(e.target.value)*/}
								{/*	setEvents({ ...events })*/}
								{/*}} */}
							</InputGroup>
						</div>
						<div className="mt-1">In rupees ₹</div>
					</div>
				</div>
				
				{/* Labour Charges Section */}
				<div className="labour-charges mt-8">
					<h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Labour Charges</h2>
					<div className="table w-full border-t border-gray-300 pt-4">
						<div className="table-header grid grid-cols-3 gap-4 text-sm text-gray-600 font-semibold uppercase tracking-wide border-b border-gray-200 pb-3 text-center">
							<div className="sr-no">Sr. No.</div>
							<div className="part-name">Labour Name</div>
							<div className="part-amount">Amount</div>
						</div>
						{labour.map((labour, index) => (
							<div className="table-row grid grid-cols-3 gap-4 text-gray-800 py-2 border-b border-gray-200 text-center">
								<div className="sr-no">{index + 1}</div>
								<div className="part-name">{labour.name}</div>
								<div className="part-amount">₹{labour.amt}</div>
							</div>))}

					</div>

				</div>
				<div className="flex flex-col-reverse   sm:pt-2 sm:pb-5 sm:flex-row">
					<div className="text-center sm:text-right sm:ml-auto">

						<div className="mt-2 text-xl font-medium text-primary">
							<InputGroup>
								<InputGroup.Text>Labour</InputGroup.Text>
								<FormInput type="number" placeholder="Food Cost" value={totLabAmt} disabled />
								{/*onChange={(e) => {*/}
								{/*	events.foodCost = Number(e.target.value)*/}
								{/*	setEvents({ ...events })*/}
								{/*}} */}
							</InputGroup>
						</div>
						<div className="mt-1">In rupees ₹</div>
					</div>
				</div>
				
			</div>



			<div className="flex flex-col-reverse pt-10 sm:px-10 sm:pb-5 sm:flex-row">
				<div className="text-center sm:text-right sm:ml-auto">
					<div className="text-base text-slate-500">Discount Amount</div>
					<div className="mt-2 text-xl font-medium text-primary">
						<InputGroup>
							<InputGroup.Text>Discount</InputGroup.Text>
							<FormInput type="number" placeholder="Discount" value={disAmt} disabled
								onChange={(e) => {
									setDisAmt(Number(e.currentTarget.value))
								}} />
						</InputGroup>
					</div>
					<div className="mt-1">In rupees ₹</div>
				</div>
			</div>
			<div className="flex flex-col-reverse px-5 pb-10 sm:px-20 sm:pb-20 sm:flex-row">

				<div className="text-center sm:text-right sm:ml-auto">
					<div className="text-base text-slate-500">Total Amount</div>
					<div className="mt-2 text-xl font-medium text-primary">₹{totalAmt()}</div>
					<div className="mt-1"></div>
				</div>
			</div>
			
		</div>
		
		<Dialog open={sendWAModalOpen} onClose={() => { setSendWAModalOpen(false) }}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Share Bill on WhatsApp</h2>
					<Button className="w-24 ml-auto" onClick={() => { setSendWAModalOpen(false) }}><Lucide icon='XCircle' /></Button>
				</Dialog.Title>
				<div className="mx-5 my-3">
					<label className='font-medium'>WhatsApp Mobile Number:</label>
					<FormInput placeholder='WhatsApp Mobile Number' className='mb-5' onChange={(e) => setWaNumber(e.target.value)} value={waNumber} maxLength={10} />
				</div>
				<Dialog.Footer className='mt-1'>
					<Button type="button" variant="primary" className=" mr-2"
						onClick={() => {
							sendWAQuotation()
							setSendWAModalOpen(false)
						}}><Lucide icon="Send" className="mr-2" /> Send Quotation on WhatsApp</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>

		
		
	</>
}