import { NavLink } from "react-router-dom";
import img from './gajananimg.jpg'
function Main() {
	return (
		<>
			{/*<div className='w-screen h-screen overflow-hidden'>*/}
			{/*<h2 className='text-white text-4xl font-medium leading-none mb-5 mt-5'>GAW</h2>*/}
			{/*<div className='flex'>*/}
			{/*	<div className='ml-auto mr-20'>*/}
			{/*	<NavLink to='/auth' className='text-white mx-5'>Login</NavLink>*/}
			{/*	<NavLink to='/home' className='text-white mx-5'>Template</NavLink>*/}
						{/*<NavLink to='/admin' className='text-white mx-5'>Admin</NavLink>*/}
						{/*<NavLink to='/user' className='text-white mx-5'>User</NavLink>*/}
						{/*<NavLink to='/vendor/1/abc' className='text-white mx-5'>Vendor</NavLink>*/}
			{/*</div>*/}
			{/*</div>*/}
			{/*<div className='flex justify-center items-center' >*/}
			{/*	<div className='text-center'>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*</div >*/}

			<div className="w-screen h-screen overflow-hidden bg-gray-800">
				{/* Header */}
				<div className=" bg-gray-800 flex justify-center items-center">
				  <h2 className="text-4xl font-medium leading-none mb-5 mt-5">
					<NavLink 
					  to="/auth" 
					  className="bg-yellow-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded"
					>
					  Login
					</NavLink>
				  </h2>
				</div>
				
				{/* Image Section */}
				<div className="flex justify-center">
					<img
						src={img}
						alt="Vehicle CRM"
						className="w-full max-w-3xl rounded-lg shadow-lg"
					/>
				</div>

				{/* Navigation Bar */}
				<div className="flex mt-5">
					<div className="ml-auto mr-20">
						<NavLink to="/auth" className="text-white mx-5">
							Login
						</NavLink>
						{/* Uncomment these as needed */}
						{/* <NavLink to='/home' className='text-white mx-5'>Template</NavLink> */}
						{/* <NavLink to='/admin' className='text-white mx-5'>Admin</NavLink> */}
						{/* <NavLink to='/user' className='text-white mx-5'>User</NavLink> */}
						{/* <NavLink to='/vendor/1/abc' className='text-white mx-5'>Vendor</NavLink> */}
					</div>
				</div>

				{/* Centered Content */}
				<div className="flex justify-center items-center h-full">
					<div className="text-center">
						{/* Additional content here */}
					</div>
				</div>
			</div>


		</>
	);
}

export default Main;
