import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as xlsx from "xlsx";
import '../../../../assets/css/tabulator-edit.css';
import Button from "../../../../base-components/Button";
import { FormInput, FormLabel } from "../../../../base-components/Form";
import { Dialog, Menu } from "../../../../base-components/Headless";
import { useLoader } from "../../../../base-components/Loader";
import Lucide from "../../../../base-components/Lucide";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Litepicker from "../../../../base-components/Litepicker";


// Extend the jsPDF interface to include the autoTable method
declare module 'jspdf' {
    interface jsPDF {
        autoTable: (options: any) => jsPDF;
    }
}
export default function Main() {
    

    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const tableRef = createRef<HTMLDivElement>();
    const tabulator = useRef<Tabulator>();
    const [id, setId] = useState(0);
    const sendButtonRef = useRef();
    const deleteButtonRef = useRef(null);
    const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
    const [successModalPreview, setSuccessModalPreview] = useState(false);
    const [modal, setModal] = useState({ msg: '', title: '', type: '' });

    const [btnText, setBtnText] = useState('Add');
    
   
    const [totPages, setTotPages] = useState(0)
    const [customClass, setCustomClass] = useState('bg-green-500')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [labour, setLabour] = useState('')
    const [part, setPart] = useState('')
    const [discount, setDiscount] = useState('')
    const [total, setTotal] = useState('')
    const [paid, setPaid] = useState('')
    const [rem, setRem] = useState('')
    const [dueAmount, setDueAmount] = useState('')
    const [billID, setBillId] = useState('')
    var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };

    useEffect(() => {//setSuccessModalPreview(true)
        initTabulator();
        reInitOnResizeWindow();
    }, []);
    const redrow = () => {
        if (tabulator.current) {
            tabulator.current.setData('/api/bill-report', { from: from, to: to })
            //console.log('redraw')
        }
    }
    const initTabulator = () => {
        if (tableRef.current) {
            tabulator.current = new Tabulator(tableRef.current, {//groupBy: "type",//data: dataList,
                ajaxFiltering: true,
                ajaxURL: "/api/bill-report", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    setTotPages(res.last_page)
                    setLabour(res.totalLab)
                    setPart(res.totalPart)
                    setDiscount(res.totalDis)
                    setTotal(res.total)
                    setPaid(res.totalPaid)
                    setRem(res.totalRemaining)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",
                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "customerName", headerFilter: "input", headerFilterPlaceholder: "Search", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
                    { title: "Mobile", field: "mobile", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Registration Number", field: "registrationNumber", headerFilter: "input",  headerFilterPlaceholder: "Search", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Vehicle Details", field: "vehicleDetails", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    //{ title: "RequestServices", field: "requestServices", print: true, minWidth: 250, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    //{ title: "Complaint", field: "complaint", print: true, minWidth: 250, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Bill Date", field: "billDate", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Labour Amount", field: "totlabAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Part Amount", field: "totPartAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Discount Amount", field: "totDisAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Bill Amount", field: "totBillAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Paid Amount", field: "paidAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Remaining Amount", field: "remainingAmt", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    {
                        title: 'Payment Due', minWidth: 100,
                        formatter: () => '<button class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Pay Due</button>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setBillId(planData.billId)
                            //console.log(planData);
                            setHeaderFooterModalPreview(true);
                        }
                    },
                   
                ],
                rowFormatter: (row) => {
                    const data = row.getData();
                    if (data.remainingAmt > 1) {
                        row.getElement().style.backgroundColor = "red"; // Set row background color to red
                        row.getElement().style.color = "white"; // Optional: Set text color to white for better contrast
                    }
                },
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
    const onExportPdf = () => {
        if (tabulator.current) {
            const doc = new jsPDF({ orientation: 'landscape', unit: 'px', format: 'a4' })
            doc.autoTable({
                head: [['Sr.', 'Name', 'Mobile', 'Registration Number', 'Vehicle Details', 'Bill Date', 'Labour Amount', 'Part Amount', 'Discount Amount', 'Bill Amount', 'Paid Amount','Remaining Amount']],
                body: tabulator.current.getData().map(row => [row.srno, row.customerName, row.mobile, row.registrationNumber, row.vehicleDetails, row.billDate, row.totlabAmt, row.totPartAmt, row.totDisAmt, row.totBillAmt, row.paidAmt, row.remainingAmt]),
            });

            doc.save('BillReport.pdf')
        }
        // tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
    };
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };
    const saveProcess = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            showLoader()
            const d = JSON.stringify({ billId: Number( billID), dueAmt: Number(dueAmount) })
            console.log(d)

            const f = await fetch('/api/add-due', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
            if (f.ok) {
                const data = await f.json()
                if (data.e) { alert(data.d); setDeleteConfirmationModal(false); }
                else {
                setSuccessModalPreview(true);
                setDeleteConfirmationModal(false);
                    if (tabulator.current) {
                        tabulator.current.setData()

                    }
                    setDueAmount('');
                    //setInvalidAlert(true); 
                }

            } else console.error(f.status, f.statusText)

            //else setInvalidAlert(true)
        } catch (error) { console.error(error) } finally { hideLoader() }
    }
    const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
   
    const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "BillReport.csv"); };
    const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "BillReport.json"); };
    const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "BillReport.html", { style: true, }); };

    const onExportXlsx = () => {
        if (tabulator.current) {
            (window as any).XLSX = xlsx;
            tabulator.current.download("xlsx", "BillReport.xlsx", { sheetName: "Products", });
        }
    };

    return <>
        
        <div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Bill Report</h2>
            <div className="flex w-full mt-4 sm:w-auto sm:mt-0">
                {/*<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/DeliveryPerson-Creation')} >Add Person</Button>   */}
            </div>
        </div>
        <div className="grid grid-cols-12 gap-4 gap-y-3">
            <div className="col-span-12 lg:col-span-2">
                <FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
                <div className="relative w-56 mx-auto">
                    <div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                        <Lucide icon="Calendar" className="w-4 h-4" />
                    </div>
                    <Litepicker
                        id="modal-datepicker-1"
                        value={from}
                        onChange={setFrom}
                        options={{
                            autoApply: false, format: 'DD/MM/YYYY',
                            showWeekNumbers: true,
                            dropdowns: {
                                minYear: 1990,
                                maxYear: null,
                                months: true,
                                years: true,
                            },
                        }}
                        className="pl-12" />
                </div>

            </div>
            <div className="col-span-12 lg:col-span-2">
                <FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
                <div className="relative w-56 mx-auto">
                    <div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                        <Lucide icon="Calendar" className="w-4 h-4" />
                    </div>
                    <Litepicker
                        id="modal-datepicker-2"
                        value={to}
                        onChange={setTo}
                        options={{
                            autoApply: false, format: 'DD/MM/YYYY',
                            showWeekNumbers: true,
                            dropdowns: {
                                minYear: 1990,
                                maxYear: null,
                                months: true,
                                years: true,
                            },
                        }}
                        className="pl-12" />
                </div>

            </div>


            <div className="col-span-12 lg:col-span-2 flex items-end ml-9">
                <Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>

            </div>
        </div>
        <div className="p-5 mt-5 intro-y box">
            <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <div className="xl:flex sm:mr-auto">
                    <Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
                    <Menu className="w-1/2 sm:w-auto">
                        <Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
                            <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
                            <Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
                        </Menu.Button>
                        <Menu.Items className="w-40">
                            <Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
                            <Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
                            <Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
                            <Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
                            <Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
                <Button onClick={() => {

                    if (tabulator.current) {
                        tabulator.current.setData()

                    }

                }}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
                <div>
                <pre>
                    <p>Total Labour Amount    : {labour}</p>
                    <p>Total Part Amount      : {part}</p>
                    <p>Total Discount Amount  : {discount}</p>
                    <p>Total Bill Amount      : {total}</p>
                    <p>Total paid Amount      : {paid}</p>
                    <p>Total remaining Amount : {rem}</p>
                 </pre>   
                </div>
                
            </div>
            <div className="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref={tableRef} className="mt-5"></div>
            </div>
            <div className='flex w-full'>
                <span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
            </div>
        </div>
        <Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} >
            <Dialog.Panel>
                <Dialog.Title>
                    <h2 className="mr-auto text-base font-medium">Add Due Amount</h2>

                </Dialog.Title>
                <Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
                    <div className="col-span-12 sm:col-span-12">
                        <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Amount
                            {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                        </FormLabel>
                        <FormInput name="name" value={dueAmount} placeholder="Enter amount"
                            onChange={(e) => { setDueAmount(e.currentTarget.value) }} />
                    </div>

                </Dialog.Description>
                <Dialog.Footer>
                    <Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
                    <Button variant="primary" type="button"
                        onClick={() => { setDeleteConfirmationModal(true); setHeaderFooterModalPreview(false); } }>Add Due Amount</Button>
                </Dialog.Footer>
            </Dialog.Panel>
        </Dialog>
        <Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >

            <Dialog.Panel className="px-5 py-10">
                <div className="text-center">
                    <div className="mb-5">Due Amount Add successfully..!</div>
                    <Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
                        setSuccessModalPreview(false);
                    }}>Ok</Button>
                </div>
            </Dialog.Panel>
        </Dialog>
        <Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
            <Dialog.Panel>
                <div className="p-5 text-center">
                   
                    <div className="mt-5 text-3xl">Are you sure?</div>
                    <div className="mt-2 text-slate-500">
                        Save ? <br />
                        This process cannot be undone.
                    </div>
                </div>
                <div className="px-5 pb-8 text-center">
                    <Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
                    <Button variant="success" className="w-24" ref={deleteButtonRef} onClick={saveProcess} >Save</Button>
                </div>
            </Dialog.Panel>
        </Dialog>
        
    </>
}