import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Litepicker from "../../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../../base-components/Form";
import Notification from "../../../../base-components/Notification";
import Lucide from "../../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import Button from "../../../../base-components/Button";
import { Dialog } from "../../../../base-components/Headless";
import { FormSelect } from "../../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../../base-components/Loader";
import TomSelect from "../../../../base-components/TomSelect";
import { Preview } from "../../../../base-components/PreviewComponent";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const navigate = useNavigate();
	const { serviceRequestId } = useParams();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);
	const [btnText, setBtnText] = useState('Entry Service');
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

	const [dateRange, setDateRange] = useState(dateRange1);
	const [isUpdate, setIsUpdate] = useState(false);
	const [invalidAlert, setInvalidAlert] = useState(false);
	const [castes, setCastes] = useState([]);
	const initClient = { kilometer: '',  }
	const [agent, setAgent] = useState(initClient)
	const [areaId, setAreaId] = useState('')
	const [areaName, setAreaName] = useState<any>()
	const [selectMultiple, setSelectMultiple] = useState([])
	const [customerId, setCustomerId] = useState('')
	const [customerName, setCustomerName] = useState<any>()
	const [mechanicId, setMechanicId] = useState('')
	const [mechanicName, setMechanicName] = useState<any>()
	const [complaint, setComplaint] = useState('')
	const [serviceName, setServiceName] = useState<any>()
	//const [clientData, setClientData] = useState([]);
	const [records, setRecords] = useState([])
	const [showList, setShowList] = useState(true);

	const schema = yup.object().shape({
		
		kilometer: yup.string().trim().required().min(2),

	}).required();

	const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
		criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
		values: agent, reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	})

	

	const handleChange = (e) => {
		const name = e.target.value
		setAgent((recentData) => ({ ...recentData, [e.target.name]: name }))
		
	}

	

	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};

		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}

	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const result = await trigger()
			if (result) {
				showLoader()
				setAgent(getFormValuesAsJson(event.target))
				//...client,
				const cl = { id: serviceRequestId, customerId: customerId, vehicleId: areaId, kilometer: agent.kilometer, services: selectMultiple, complaint: complaint, mechanicId }
				//const ts = { id: Number(eventId), eventName: agent.eventName, eventAddress: agent.eventAddress, }
				console.log(cl)
				const d = JSON.stringify(cl)

				const f = await fetch('/api/add-services', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
				if (f.ok) {
					const data = await f.json();
					/*console.log("success", data);*/
					setInvalidAlert(true);
				} else console.error(f.status, f.statusText)
			} else console.log('validation error')
		} catch (error) { console.error(error) } finally { hideLoader() }
	}

	async function getCategories() {
		try {

			showLoader()
			const f = await fetch('/api/get-customer')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setCustomerName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	//vehicle info
	async function getvehicleName() {
		try {

			showLoader()
			const f = await fetch(`/api/get-vehicle/${customerId}`)
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAreaName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	//get service
	async function getService() {
		try {

			showLoader()
			const f = await fetch('/api/get-service')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setServiceName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function getMechanic() {
		try {

			showLoader()
			const f = await fetch('/api/get-mechanic')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setMechanicName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	const fetchServiceDetails = async () => {
		try {
			showLoader();
			const response = await fetch(`/api/get-service-details/${serviceRequestId}`);
			const data = await response.json();

			if (data.e === false) {
				// Update state with fetched data
				setCustomerId(data.data.customerId);
				setMechanicId(data.data.mechanicId);
				setAreaId(data.data.vehicleId);
				setAgent((prev) => ({ ...prev, kilometer: data.data.currentKm }));
				setSelectMultiple(data.data.services);
				setComplaint(data.data.complaint);
				
			} else {
				// Handle error fetching data
				alert('Error fetching service details');
			}
		} catch (error) {
			alert('Error fetching service details');
		} finally {
			hideLoader();
		}
	};
	useEffect(() => { getCategories(); getService(); getMechanic() }, [])
	useEffect(() => { getvehicleName() }, [customerId])
	useEffect(() => {
		
		console.log(serviceRequestId)
		if (serviceRequestId) {
			fetchServiceDetails();
		}
	}, []); // Depend on serviceRequestId to fetch data again when it changes

	return (
		<>

			<div className="flex items-center mt-8 intro-y">
				<h1 className="mr-auto text-lg font-medium">Service Entry Form</h1>
				
			</div>
			<div className="grid grid-cols-12 gap-6 mt-5">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="p-5 intro-y box">
						<form className="validate-form" onSubmit={onSubmit2}>

							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Customer Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								{/*<FormSelect onChange={(e) => setCustomerId(e.currentTarget.value)} name='categoryId' value={customerId} aria-label="Default select example">*/}
								{/*	<option value='0'>Select Type</option>*/}
								{/*	{(customerName != undefined && customerName && customerName.length > 0) ? customerName.map((cat: any) => (*/}
								{/*		<option value={cat.id} key={cat.id}>{cat.name}</option>*/}
								{/*	)) : <option>Nothing to show</option>}*/}
								{/*</FormSelect>*/}
								<TomSelect onChange={ setCustomerId} value={customerId} options={{
									placeholder: "Select Customer",
								}} className="w-full">
									<option value='0'>Select Customer</option>
									{(customerName != undefined && customerName && customerName.length > 0) ? customerName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>
									)) : <option>Nothing to show</option>}
								</TomSelect>
							</div>
							{customerId && 
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Select vehicle
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								<FormSelect onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
									<option value='0'>Select Vehicle</option>
									{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>
									)) : <option>Nothing to show</option>}
								</FormSelect>
								</div>}
							<div className="input-form mb-5">
								<FormLabel className="flex flex-col w-full sm:flex-row">Select Service
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
									{/*	Required at least 5 character*/}
									{/*</span>*/}
								</FormLabel>
								<Preview>
									<TomSelect
										value={selectMultiple}
										onChange={setSelectMultiple}
										options={{
											placeholder: "Select Service",
										}}
										className="w-full"
										multiple
									>
										{(serviceName != undefined && serviceName && serviceName.length > 0) ? serviceName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>)):
										<option >No Any Service</option>
										}
									</TomSelect>
								</Preview>
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Mechanic Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								{/*<FormSelect onChange={(e) => setCustomerId(e.currentTarget.value)} name='categoryId' value={customerId} aria-label="Default select example">*/}
								{/*	<option value='0'>Select Type</option>*/}
								{/*	{(customerName != undefined && customerName && customerName.length > 0) ? customerName.map((cat: any) => (*/}
								{/*		<option value={cat.id} key={cat.id}>{cat.name}</option>*/}
								{/*	)) : <option>Nothing to show</option>}*/}
								{/*</FormSelect>*/}
								<TomSelect onChange={setMechanicId} value={mechanicId} options={{
									placeholder: "Select Mechanic",
								}} className="w-full">
									<option value='0'>Select Mechanic</option>
									{(mechanicName != undefined && mechanicName && mechanicName.length > 0) ? mechanicName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>
									)) : <option>Nothing to show</option>}
								</TomSelect>
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Kilo-Metre
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								<FormInput id='fiName' {...register("kilometer")} value={agent.kilometer} placeholder="Enter Kilo-meter"
									onChange={(e) => setAgent({ ...agent, kilometer: e.target.value })} className={clsx({ "border-danger": errors.kilometer })} autoComplete='off' />

							</div>

							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Complaint
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormTextarea value={complaint} disabled={isUpdate}
										onChange={(e) => setComplaint(e.target.value)}  
										placeholder="Enter Complaint" />
									</div>
							</div>
							

							

							<Button variant="primary" type="submit" className="m-2">{btnText}</Button>
						</form>
					</div>

					<Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
						<Dialog.Panel className="px-5 py-10">
							<div className="text-center">
								<div className="mb-5">{btnText} Successfully...!</div>
								<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => { setInvalidAlert(false); navigate('/finance/work-list') }}>Ok</Button>
							</div>
						</Dialog.Panel>
					</Dialog>
				</div>
			</div>
		</>
	);
}

export default Main;