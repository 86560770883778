import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Litepicker from "../../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../../base-components/Form";
import Notification from "../../../../base-components/Notification";
import Lucide from "../../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import Button from "../../../../base-components/Button";
import { Dialog } from "../../../../base-components/Headless";
import { FormSelect } from "../../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../../base-components/Loader";
import TomSelect from "../../../../base-components/TomSelect";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const navigate = useNavigate();
	const { id } = useParams();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);
	const [btnText, setBtnText] = useState('Create Customer Vehicle');
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

	const [dateRange, setDateRange] = useState(dateRange1);
	const [isUpdate, setIsUpdate] = useState(false);
	const [invalidAlert, setInvalidAlert] = useState(false);
	const [castes, setCastes] = useState([]);
	const initClient = { name: '', number: '' }
	const [agent, setAgent] = useState(initClient)
	const [areaId, setAreaId] = useState('')
	const [areaName, setAreaName] = useState<any>()
	const [customerId, setCustomerId] = useState('')
	const [customerName, setCustomerName] = useState<any>()

	//const [clientData, setClientData] = useState([]);
	const [engineNumber, setEngineNumber] = useState('')
	const [chesesNumber, setChesesNumber] = useState('');

	const schema = yup.object().shape({
		//id: yup.number(),
		name: yup.string().trim().required().min(2),
		number: yup.string().required('Caste is required'),
		//.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
		//address: yup.string().required().min(4),
		//aadhar: yup.string().required().min(12),
		//email: yup.string().email(),
		//mobile1: yup.string().required().min(10).max(10)
		//	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
		//mobile2: yup.string().min(10).max(10)//.required()
		//	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
	}).required();

	const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
		criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
		values: agent, reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	})

	

	const handleChange = (e) => {
		const name = e.target.value
		setAgent((recentData) => ({ ...recentData, [e.target.name]: name }))
		
	}

	

	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};

		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}

	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const result = await trigger()
			if (result) {
				showLoader()
				setAgent(getFormValuesAsJson(event.target))
				//...client,
				const cl = {id:id, name: agent.name, number: agent.number, engineNumber: engineNumber, chesesNumber: chesesNumber, customerId:Number( customerId), brandId: Number(areaId) }
				//const ts = { id: Number(eventId), eventName: agent.eventName, eventAddress: agent.eventAddress, }
				console.log(cl)
				const d = JSON.stringify(cl)

				const f = await fetch('/api/add-vehicle-details', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
				if (f.ok) {
					const data = await f.json();
					/*console.log("success", data);*/
					if(data.e) alert(data.msg)
					else setInvalidAlert(true);
				} else console.error(f.status, f.statusText)
			} else console.log('validation error')
		} catch (error) { console.error(error) } finally { hideLoader() }
	}

	async function getCategories() {
		try {

			showLoader()
			const f = await fetch('/api/get-customer')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setCustomerName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	//barand name
	async function getvehicleBrandName() {
		try {

			showLoader()
			const f = await fetch('/api/get-brandname')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAreaName(r.d)
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function getvehicleCustomerInfo() {
		try {

			showLoader()
			const f = await fetch(`/api/get-customer-vehicle-info/${id}`)
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setAgent((prev) => ({ ...prev, name: r.d.vehicleVeriantName, number: r.d.registrationNumber }));
					setCustomerId(r.d.customerId);
					setAreaId(r.d.vehicleBrandId);
					setEngineNumber(r.d.engineNumber);
					setChesesNumber(r.d.chesesNumber);
					setBtnText('Update Customer Vehicle')
					//console.log(personId);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	useEffect(() => {
		getCategories();
		getvehicleBrandName();
		getvehicleCustomerInfo();
	}, [])
	return (
		<>

			<div className="flex items-center mt-8 intro-y">
				<h1 className="mr-auto text-lg font-medium">Customer Vehicle Info</h1>
				
			</div>
			<div className="grid grid-cols-12 gap-6 mt-5">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="p-5 intro-y box">
						<form className="validate-form" onSubmit={onSubmit2}>

							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Customer Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								{/*<FormSelect onChange={(e) => setCustomerId(e.currentTarget.value)} name='categoryId' value={customerId} aria-label="Default select example">*/}
								{/*	<option value='0'>Select Type</option>*/}
								{/*	{(customerName != undefined && customerName && customerName.length > 0) ? customerName.map((cat: any) => (*/}
								{/*		<option value={cat.id} key={cat.id}>{cat.name}</option>*/}
								{/*	)) : <option>Nothing to show</option>}*/}
								{/*</FormSelect>*/}
								<TomSelect onChange={setCustomerId} value={customerId} options={{
									placeholder: "Select your favorite actors",
								}} className="w-full">
									<option value='0'>Select Customer</option>
									{(customerName != undefined && customerName && customerName.length > 0) ? customerName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>
									)) : <option>Nothing to show</option>}
								</TomSelect>
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Vehicle Brand Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								<FormSelect onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
									<option value='0'>Select Vehicle Brand</option>
									{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
										<option value={cat.id} key={cat.id}>{cat.name}</option>
									)) : <option>Nothing to show</option>}
								</FormSelect>
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Vehicle Veriant Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								<FormInput id='fiName' {...register("name")} value={agent.name} placeholder="Enter Veriant Name"
									onChange={(e) => setAgent({ ...agent, name: e.target.value })} className={clsx({ "border-danger": errors.name })} autoComplete='off' />

							</div>

							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Vehicle Number (Registration Number)
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput {...register("number")} type="text" value={agent.number} disabled={isUpdate}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.number })}
										placeholder="Enter Registration Number" />
									{errors.number && (<div className="mt-2 text-danger"> {typeof errors.number.message === "string" && errors.number.message} </div>)}
								</div>
							</div>
							
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Vehicle Engine Number
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput type="text" value={engineNumber}
										onChange={(e) => setEngineNumber(e.target.value)} maxLength={200} 
										placeholder="Enter engine Number" />
										</div>
							</div>
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Vehicle cheses Number
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput type="text" value={chesesNumber}
										onChange={(e) => setChesesNumber(e.target.value)} maxLength={200}
										placeholder="Enter cheses Number" />
								</div>
							</div>
							

							<Button variant="primary" type="submit" className="m-2">{btnText}</Button>
						</form>
					</div>

					<Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
						<Dialog.Panel className="px-5 py-10">
							<div className="text-center">
								<div className="mb-5">{btnText} Successfully...!</div>
								<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => { setInvalidAlert(false); navigate('/finance/customer-vehicle-list') }}>Ok</Button>
							</div>
						</Dialog.Panel>
					</Dialog>
				</div>
			</div>
		</>
	);
}

export default Main;