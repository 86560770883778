
import Litepicker from "../../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../../base-components/Form";
import Notification from "../../../../base-components/Notification";

import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../../base-components/Loader";
import Dialog from "../../../../base-components/Headless/Dialog";
import Lucide from "../../../../base-components/Lucide";

import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import Button from "../../../../base-components/Button";


function Main() {
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();
    const { planid } = useParams();
    const [memberId, setMemberId] = useState('')
 
    const tableRef = createRef<HTMLDivElement>()
    const tabulator = useRef<Tabulator>()

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [btnText, setBtnText] = useState('Add');
    const deleteButtonRef = useRef(null);
    const eventId = useSelector((state: RootState) => state.eventId.eventId);
    const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
    const [successModalPreview, setSuccessModalPreview] = useState(false);
    const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");
    const [customers, setCustomers] = useState([])
    //const initClient = { amt: 0, installation: 0, name: '', duration: 0, processingfee: 0, Frequency: 0 }
    const [areaname, setAreaName] = useState('')
    const [totPages, setTotPages] = useState(0)
    useEffect(() => {
        initTabulator()
        reInitOnResizeWindow()
},[])
    const redrow = () => {
        if (tabulator.current) {
            tabulator.current.setData()
            console.log('redraw')
        }
    }
    const initTabulator = () => {
        if (tableRef.current) {
            // console.log(groupid);
            tabulator.current = new Tabulator(tableRef.current, {
                ajaxFiltering: true,

                ajaxURL: `/api/service-list`, paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    //console.log('hello');
                    setTotPages(res.last_page)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
                    console.log(res)
                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",

                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "name", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true },

                    {
                        title: 'Edit', minWidth: 120, hozAlign: "center", headerHozAlign: 'center',
                        formatter: () => '<i data-lucide="edit" class="w-8 h-8 mr-1 text-success"></i>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            // getPymentInfo(planData.id);
                            //setBasicModalPreview(true);
                            // console.log(planData);
                            setHeaderFooterModalPreview(true);
                            setMemberId(planData.id);
                            setAreaName(planData.name);
                            setBtnText('Update')
                            //setSuperlargeModalSizePreview1(true);
                            //navigate(`/finance/expenses/${planData.id}`);
                        },
                    },
                    {
                        title: 'Delete', minWidth: 100, hozAlign: "center", headerHozAlign: 'center',
                        formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setMemberId(planData.id);
                            setDeleteConfirmationModal(true);
                        },
                    },

                ],
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
    

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };

   
   

    const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
                showLoader()
            const d = JSON.stringify({ id: memberId, Name: areaname })
               console.log(d)

            const f = await fetch('/api/add-service', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
                if (f.ok) {
                    const data = await f.json()
                    setSuccessModalPreview(true);
                    setHeaderFooterModalPreview(false);
                    redrow();
                    //setInvalidAlert(true);

                } else console.error(f.status, f.statusText)
            
            //else setInvalidAlert(true)
        } catch (error) { console.error(error) } finally { hideLoader() }
    }
    async function deleteprocess() {
        try {
            showLoader()//body: JSON.stringify(process), 
            const f = await fetch(`/api/delete-service/${memberId}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, })
            if (f.ok) {
                const j = await f.json()
                if (j.e) { setDeleteConfirmationModal(false); alert(j.msg); }
                else {
                    
                    setDeleteConfirmationModal(false);
                    redrow();
                }

            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
   
    useEffect(() => {
       // console.log(areaname);
    }, [areaname])
   

    return (
        <>
            <div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
                <h2 className="mr-auto text-lg font-medium">Add Service</h2>
                <div className="flex w-full mt-4 sm:w-auto sm:mt-0">
                    <Button variant="primary" className="mr-2 shadow-md" onClick={() => { setHeaderFooterModalPreview(true); setAreaName(''); setBtnText("Add"); setMemberId(null); }} >Add Service</Button>
                   
                </div>
            </div>
            <div className="p-5 mt-5 intro-y box">
                <div className="overflow-x-auto scrollbar-hidden">
                    <div id="tabulator" ref={tableRef} className="mt-5"></div>
                </div>
            </div>

           
            <Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} >
                <Dialog.Panel>
                    <Dialog.Title>
                        <h2 className="mr-auto text-base font-medium">{btnText} Service</h2>
                       
                    </Dialog.Title>
                    <Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12 sm:col-span-12">
                            <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Service Name
                                {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                            </FormLabel>
                            <FormInput name="name" value={areaname} placeholder="Enter Service Name"
                                onChange={(e) => { setAreaName(e.currentTarget.value) }} />
                        </div>
                        
                    </Dialog.Description>
                    <Dialog.Footer>
                        <Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
                        <Button variant="primary" type="button"
                            onClick={onSubmit2}>{btnText} Service</Button>
                    </Dialog.Footer>
                </Dialog.Panel>
            </Dialog>
            <Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
               
                <Dialog.Panel className="px-5 py-10">
                    <div className="text-center">
                        <div className="mb-5">Service {btnText} successfully..!</div>
                        <Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
                            setSuccessModalPreview(false);
                        }}>Ok</Button>
                    </div>
                </Dialog.Panel>
            </Dialog>
            <Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
                <Dialog.Panel>
                    <div className="p-5 text-center">
                        <Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
                        <div className="mt-5 text-3xl">Are you sure?</div>
                        <div className="mt-2 text-slate-500">
                            Delete ? <br />
                            This process cannot be undone.
                        </div>
                    </div>
                    <div className="px-5 pb-8 text-center">
                        <Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
                        <Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteprocess} >Delete</Button>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    );
}

export default Main;