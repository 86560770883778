import { useRoutes } from "react-router-dom";
import Website from "../app/Website";
import AppLogin from "../app/auth/AppLogin";
import RequireAuth from "../app/auth/RequireAuth";
import AddMenu from "../app/user/AddMenu";

import SideMenu from "../layouts/SideMenu";
import SimpleMenu from "../layouts/SimpleMenu";
import TopMenu from "../layouts/TopMenu";
import Accordion from "../pages/Accordion";
import AddProduct from "../pages/AddProduct";
import Alert from "../pages/Alert";
import BlogLayout1 from "../pages/BlogLayout1";
import BlogLayout2 from "../pages/BlogLayout2";
import BlogLayout3 from "../pages/BlogLayout3";
import Button from "../pages/Button";
import Calendar from "../pages/Calendar";
import Categories from "../pages/Categories";
import ChangePassword from "../pages/ChangePassword";
import Chart from "../pages/Chart";
import Chat from "../pages/Chat";
import CrudDataList from "../pages/CrudDataList";
import CrudForm from "../pages/CrudForm";
import DashboardOverview1 from "../pages/DashboardOverview1";
import DashboardOverview2 from "../pages/DashboardOverview2";
import DashboardOverview3 from "../pages/DashboardOverview3";
import DashboardOverview4 from "../pages/DashboardOverview4";
import Datepicker from "../pages/Datepicker";
import Dropdown from "../pages/Dropdown";
import ErrorPage from "../pages/ErrorPage";
import FaqLayout1 from "../pages/FaqLayout1";
import FaqLayout2 from "../pages/FaqLayout2";
import FaqLayout3 from "../pages/FaqLayout3";
import FileManager from "../pages/FileManager";
import FileUpload from "../pages/FileUpload";
import Icon from "../pages/Icon";
import ImageZoom from "../pages/ImageZoom";
import Inbox from "../pages/Inbox";
import InvoiceLayout1 from "../pages/InvoiceLayout1";
import InvoiceLayout2 from "../pages/InvoiceLayout2";
import LoadingIcon from "../pages/LoadingIcon";
import Login from "../pages/Login";
import Modal from "../pages/Modal";
import Notification from "../pages/Notification";
import PointOfSale from "../pages/PointOfSale";
import Post from "../pages/Post";
import PricingLayout1 from "../pages/PricingLayout1";
import PricingLayout2 from "../pages/PricingLayout2";
import ProductGrid from "../pages/ProductGrid";
import ProductList from "../pages/ProductList";
import ProfileOverview1 from "../pages/ProfileOverview1";
import ProfileOverview2 from "../pages/ProfileOverview2";
import ProfileOverview3 from "../pages/ProfileOverview3";
import ProgressBar from "../pages/ProgressBar";
import Register from "../pages/Register";
import RegularForm from "../pages/RegularForm";
import RegularTable from "../pages/RegularTable";
import Reviews from "../pages/Reviews";
import SellerDetail from "../pages/SellerDetail";
import SellerList from "../pages/SellerList";
import Slideover from "../pages/Slideover";
import Slider from "../pages/Slider";
import Tab from "../pages/Tab";
import Tabulator from "../pages/Tabulator";
import TomSelect from "../pages/TomSelect";
import Tooltip from "../pages/Tooltip";
import TransactionDetail from "../pages/TransactionDetail";
import TransactionList from "../pages/TransactionList";
import Typography from "../pages/Typography";
import UpdateProfile from "../pages/UpdateProfile";
import UsersLayout1 from "../pages/UsersLayout1";
import UsersLayout2 from "../pages/UsersLayout2";
import UsersLayout3 from "../pages/UsersLayout3";
import Validation from "../pages/Validation";
import WizardLayout1 from "../pages/WizardLayout1";
import WizardLayout2 from "../pages/WizardLayout2";
import WizardLayout3 from "../pages/WizardLayout3";
import WysiwygEditor from "../pages/WysiwygEditor";

import Dashboard from "../app/finance/Dashboard";
import Expenses from "../app/finance/WorkshopCRM/Expenses";
import ExpensesList from "../app/finance/WorkshopCRM/ExpensesList";
import CustomerAdd from "../app/finance/WorkshopCRM/CustomerAdd";
import CustomerVehicleAdd from "../app/finance/WorkshopCRM/CustomerVehicleAdd";
import NewServiceEntry from "../app/finance/WorkshopCRM/NewServiceEntry";
import ServiceList from "../app/finance/WorkshopCRM/ServiceList";
import VehicleBrandCreation from "../app/finance/WorkshopCRM/VehicleBrandCreation";
import ServiceCreation from "../app/finance/WorkshopCRM/ServiceCreation";
import AreaCreation from "../app/finance/WorkshopCRM/AreaCreation";
import Bill from "../app/finance/WorkshopCRM/Bill";
import CustomerList from "../app/finance/WorkshopCRM/CustomerList";
import CustomerVehicleList from "../app/finance/WorkshopCRM/CustomerVehicleList";
import ServiceCompleteList from "../app/finance/WorkshopCRM/ServiceCompleteList";
import CompleteBill from "../app/finance/WorkshopCRM/CompleteBill";
import FollowUpList from "../app/finance/WorkshopCRM/FollowUpList";
import BillReport from "../app/finance/WorkshopCRM/BillReport";
import MechanicAdd from "../app/finance/WorkshopCRM/MechanicAdd";

function Router() {
    const routes = [
        { path: "", element: <Website />, },
       
        { path: "/auth", element: <AppLogin />, },
        {
            path: "/finance", element: <RequireAuth />, children: [{
                path: "", element: <SideMenu />,
                children: [
                    { path: "", element: <Dashboard />, },
                    { path: "new-customer", element: <CustomerAdd />, }, // waterbox
                    { path: "new-customer/:id", element: <CustomerAdd />, }, // waterbox
                    { path: "new-customer-vehicle", element: <CustomerVehicleAdd />, }, // waterbox
                    { path: "new-customer-vehicle/:id", element: <CustomerVehicleAdd />, }, // waterbox
                    { path: "customer-list", element: <CustomerList />, }, // waterbox
                    { path: "customer-vehicle-list", element: <CustomerVehicleList />, }, // waterbox
                    { path: "new-service-entry", element: <NewServiceEntry />, }, // waterbox
                    { path: "new-service-entry/:serviceRequestId", element: <NewServiceEntry />, }, // waterbox
                    { path: "work-list", element: <ServiceList />, }, // waterbox
                    { path: "work-complete-list", element: <ServiceCompleteList />, }, // waterbox
                    { path: "follow-up-list", element: <FollowUpList />, }, // waterbox
                    { path: "bill-list", element: <BillReport />, }, // waterbox
                    { path: "add-service", element: <ServiceCreation />, }, // waterbox
                    { path: "add-vehicle", element: <VehicleBrandCreation />, }, // waterbox
                    { path: "add-are", element: <AreaCreation />, }, // waterbox
                    { path: "add-mechanic", element: <MechanicAdd />, }, // waterbox
                    { path: "expenses", element: <Expenses />, },
                    { path: "expenses/:planid", element: <Expenses />, },
                    { path: "expenses-list", element: <ExpensesList />, },
                    { path: "bill/:serviceId", element: <Bill />, }, // waterbox
                    { path: "complete-bill/:serviceId", element: <CompleteBill />, }, // waterbox
                    //{ path: "Bill-recevied", element: <BillPaymentRecevied />, }, // waterbox
                    //{ path: "unit-creation", element: <UnitCreation />, }, // waterbox
                    //{ path: "Products-creation", element: <ProductsCreation />, }, // waterbox
                    //{ path: "productunit-transaction", element: <ProductUnitTrasaction />, }, // waterbox
                    //{ path: "rawproduct-closing", element: <RawProductAssignClosing />, }, // waterbox

                    //{ path: "billreport-list", element: <BillReportList />, }, // waterbox
                    //{ path: "udharijama-list", element: <UdhariJamaReportList />, }, // waterbox
                    //{ path: "udharidene-list", element: <UdhariDeneReportList />, }, // waterbox

                    //{ path: "rawproductstockoneliter-list", element: <RawProductStockOneliterReportList />, }, // waterbox
                    //{ path: "rawproductstockhalfliter-list", element: <RawProductStockHalfliterReportList />, }, // waterbox
                    //{ path: "daily-cashflow", element: <DailyCashFlowReport />, }, // waterbox
                    //{ path: "company-cashflow", element: <CompanyCashFlowReport />, }, // waterbox
                    //{ path: "supplier-Creation", element: <SupplierCreation />, }, // waterbox
                    //{ path: "delevery-person-list", element: <DeleveryPersonList />, }, // waterbox


                    //{ path: "plan-creation", element: <PlanCreation/>, },
                    //{ path: "plan-creation/:planid", element: <PlanCreation />, },
                    //{ path: "plan-list", element: <PlanList />, },
                    //{ path: "area-Creation", element: <AreaCreation />, },
                    //{ path: "agent-creation", element: <AgentCreation />, },
                    //{ path: "agent-creation/:agentid", element: <AgentCreation />, },
                    //{ path: "agent-list", element: <AgentList />, },
                    //{ path: "agent-account-ledger", element: <AgentAccountLedger />, },
                    //{ path: "agent-business-details", element: <AgentBusinessDetails />, },
                    //{ path: "agent-business-details", element: <AgentBusinessDetails />, },
                    //{ path: "agent-tracking-system", element: <AgentTrackingSystem />, },
                    //{ path: "user-list/:id", element: <UserList />, },
                    //{ path: "enquiry-list", element: <EnquiryList />, },
                   // { path: "loan-users/:groupid", element: <LoanUsers />, },
                   //// { path: "loan-users", element: <LoanUsers />, },
                   // { path: "loan-approval-list", element: <LoanApprovalList />, },
                   // { path: "loan-disbursement-list", element: <LoanDisbursementList />, },
                   // { path: "collection-approval", element: <CollectionApproval />, },
                   // { path: "processing-fee-report", element: <ProcessingFeeReport />, },
                   // { path: "loan-recovery-report", element: <LoanRecoveryReport />, },
                   // { path: "installment-report", element: <InstallmentReport />, },
                    
                    { path: "product-list", element: <ExpensesList />, },
                    
                ],
            }]
        },
       
        {
            path: "/admin", element: <RequireAuth />, children: [{
                path: "", element: <SideMenu />,
                children: [
                    //{ path: "", element: <Schedular2 />, },
                    //{ path: "client", element: <Client />, },
                    //{ path: "sub-event", element: <Menu />, },
                    //{ path: "income-expense", element: <IncomeExpense />, },
                    //{ path: "after-event", element: <AfterEvent />, },
                    //{ path: "nav-menu", element: <NavMenu />, },
                    //{ path: "nav-menu-add", element: <NavMenuAdd />, },
                    //{ path: "utensils-outward", element: <UtensilsOutward />, },
                    //{ path: "stats", element: <DashboardOverview1 />, },
                    //{ path: "create-employee", element: <CreateEmployee />, },
                    //{ path: "raw-material-list", element: <RawMaterialList />, },
                    //{ path: "total-material-list", element: <TotalRawMaterial />, },
                    //{ path: "order-request-rates", element: <OrderRequestRates />, },
                    //{ path: "add-maharaj", element: <AddMaharaj />, },
                    //{ path: "add-vendor", element: <AddVendor />, },
                    //{ path: "add-inventry", element: <AddInventry />, },
                    //{ path: "customers", element: <CustomerList />, },
                    //{ path: "events/:customer_id", element: <EventList />, },
                    //{ path: "utensils", element: <Utensils />, },
                    //{ path: "RawMaterials", element: <RawMaterials />, },
                    //{ path: "MaterialCategory", element: <MaterialCategory />, },
                    //{ path: "ProcessMaster", element: <ProcessMaster />, },
                    //{ path: "DishMaster", element: <DishMaster />, },
                    //{ path: "Quotation", element: <Quotation />, },
                    //{ path: "UtensilsCategory", element: <UtensilsCategory />, },
                    //{ path: "dish-priority", element: <DishPriority />, },
                    //{ path: "vendor-category", element: <AddVendorCategory />, },
                ],
            }]
        },
        {
            path: "/user", element: <RequireAuth />, children: [{
                path: "", element: <SideMenu />,
                children: [
                    { path: "", element: <DashboardOverview1 />, },
                    { path: "AddMenu", element: <AddMenu />, },
                ],
            }]
        },
        {//, element: <RequireAuth />
            path: "/", children: [{
                path: "/", element: <SideMenu />,
                children: [
                    { path: "/home", element: <DashboardOverview1 />, },
                    { path: "dashboard-overview-2", element: <DashboardOverview2 />, },
                    { path: "dashboard-overview-3", element: <DashboardOverview3 />, },
                    { path: "dashboard-overview-4", element: <DashboardOverview4 />, },
                    { path: "categories", element: <Categories />, },
                    { path: "add-product", element: <AddProduct />, },
                    { path: "product-list", element: <ProductList />, },
                    { path: "product-grid", element: <ProductGrid />, },
                    { path: "transaction-list", element: <TransactionList />, },
                    { path: "transaction-detail", element: <TransactionDetail />, },
                    { path: "seller-list", element: <SellerList />, },
                    { path: "seller-detail", element: <SellerDetail />, },
                    { path: "reviews", element: <Reviews />, },
                    { path: "inbox", element: <Inbox />, },
                    { path: "file-manager", element: <FileManager />, },
                    { path: "point-of-sale", element: <PointOfSale />, },
                    { path: "chat", element: <Chat />, },
                    { path: "post", element: <Post />, },
                    { path: "calendar", element: <Calendar />, },
                    { path: "crud-data-list", element: <CrudDataList />, },
                    { path: "crud-form", element: <CrudForm />, },
                    { path: "users-layout-1", element: <UsersLayout1 />, },
                    { path: "users-layout-2", element: <UsersLayout2 />, },
                    { path: "users-layout-3", element: <UsersLayout3 />, },
                    { path: "profile-overview-1", element: <ProfileOverview1 />, },
                    { path: "profile-overview-2", element: <ProfileOverview2 />, },
                    { path: "profile-overview-3", element: <ProfileOverview3 />, },
                    { path: "wizard-layout-1", element: <WizardLayout1 />, },
                    { path: "wizard-layout-2", element: <WizardLayout2 />, },
                    { path: "wizard-layout-3", element: <WizardLayout3 />, },
                    { path: "blog-layout-1", element: <BlogLayout1 />, },
                    { path: "blog-layout-2", element: <BlogLayout2 />, },
                    { path: "blog-layout-3", element: <BlogLayout3 />, },
                    { path: "pricing-layout-1", element: <PricingLayout1 />, },
                    { path: "pricing-layout-2", element: <PricingLayout2 />, },
                    { path: "invoice-layout-1", element: <InvoiceLayout1 />, },
                    { path: "invoice-layout-2", element: <InvoiceLayout2 />, },
                    { path: "faq-layout-1", element: <FaqLayout1 />, },
                    { path: "faq-layout-2", element: <FaqLayout2 />, },
                    { path: "faq-layout-3", element: <FaqLayout3 />, },
                    { path: "update-profile", element: <UpdateProfile />, },
                    { path: "change-password", element: <ChangePassword />, },
                    { path: "regular-table", element: <RegularTable />, },
                    { path: "tabulator", element: <Tabulator />, },
                    { path: "modal", element: <Modal />, },
                    { path: "slideover", element: <Slideover />, },
                    { path: "notification", element: <Notification />, },
                    { path: "tab", element: <Tab />, },
                    { path: "accordion", element: <Accordion />, },
                    { path: "button", element: <Button />, },
                    { path: "alert", element: <Alert />, },
                    { path: "progress-bar", element: <ProgressBar />, },
                    { path: "tooltip", element: <Tooltip />, },
                    { path: "dropdown", element: <Dropdown />, },
                    { path: "typography", element: <Typography />, },
                    { path: "icon", element: <Icon />, },
                    { path: "loading-icon", element: <LoadingIcon />, },
                    { path: "regular-form", element: <RegularForm />, },
                    { path: "datepicker", element: <Datepicker />, },
                    { path: "tom-select", element: <TomSelect />, },
                    { path: "file-upload", element: <FileUpload />, },
                    { path: "wysiwyg-editor", element: <WysiwygEditor />, },
                    { path: "validation", element: <Validation />, },
                    { path: "chart", element: <Chart />, },
                    { path: "slider", element: <Slider />, },
                    { path: "image-zoom", element: <ImageZoom />, },
                ],
            }],
        },
        {
            path: "/simple-menu", element: <SimpleMenu />,
            children: [
                { path: "dashboard-overview-1", element: <DashboardOverview1 />, },
                { path: "dashboard-overview-2", element: <DashboardOverview2 />, },
                { path: "dashboard-overview-3", element: <DashboardOverview3 />, },
                { path: "dashboard-overview-4", element: <DashboardOverview4 />, },
                { path: "categories", element: <Categories />, },
                { path: "add-product", element: <AddProduct />, },
                { path: "product-list", element: <ProductList />, },
                { path: "product-grid", element: <ProductGrid />, },
                { path: "transaction-list", element: <TransactionList />, },
                { path: "transaction-detail", element: <TransactionDetail />, },
                { path: "seller-list", element: <SellerList />, },
                { path: "seller-detail", element: <SellerDetail />, },
                { path: "reviews", element: <Reviews />, },
                { path: "inbox", element: <Inbox />, },
                { path: "file-manager", element: <FileManager />, },
                { path: "point-of-sale", element: <PointOfSale />, },
                { path: "chat", element: <Chat />, },
                { path: "post", element: <Post />, },
                { path: "calendar", element: <Calendar />, },
                { path: "crud-data-list", element: <CrudDataList />, },
                { path: "crud-form", element: <CrudForm />, },
                { path: "users-layout-1", element: <UsersLayout1 />, },
                { path: "users-layout-2", element: <UsersLayout2 />, },
                { path: "users-layout-3", element: <UsersLayout3 />, },
                { path: "profile-overview-1", element: <ProfileOverview1 />, },
                { path: "profile-overview-2", element: <ProfileOverview2 />, },
                { path: "profile-overview-3", element: <ProfileOverview3 />, },
                { path: "wizard-layout-1", element: <WizardLayout1 />, },
                { path: "wizard-layout-2", element: <WizardLayout2 />, },
                { path: "wizard-layout-3", element: <WizardLayout3 />, },
                { path: "blog-layout-1", element: <BlogLayout1 />, },
                { path: "blog-layout-2", element: <BlogLayout2 />, },
                { path: "blog-layout-3", element: <BlogLayout3 />, },
                { path: "pricing-layout-1", element: <PricingLayout1 />, },
                { path: "pricing-layout-2", element: <PricingLayout2 />, },
                { path: "invoice-layout-1", element: <InvoiceLayout1 />, },
                { path: "invoice-layout-2", element: <InvoiceLayout2 />, },
                { path: "faq-layout-1", element: <FaqLayout1 />, },
                { path: "faq-layout-2", element: <FaqLayout2 />, },
                { path: "faq-layout-3", element: <FaqLayout3 />, },
                { path: "update-profile", element: <UpdateProfile />, },
                { path: "change-password", element: <ChangePassword />, },
                { path: "regular-table", element: <RegularTable />, },
                { path: "tabulator", element: <Tabulator />, },
                { path: "modal", element: <Modal />, },
                { path: "slideover", element: <Slideover />, },
                { path: "notification", element: <Notification />, },
                { path: "tab", element: <Tab />, },
                { path: "accordion", element: <Accordion />, },
                { path: "button", element: <Button />, },
                { path: "alert", element: <Alert />, },
                { path: "progress-bar", element: <ProgressBar />, },
                { path: "tooltip", element: <Tooltip />, },
                { path: "dropdown", element: <Dropdown />, },
                { path: "typography", element: <Typography />, },
                { path: "icon", element: <Icon />, },
                { path: "loading-icon", element: <LoadingIcon />, },
                { path: "regular-form", element: <RegularForm />, },
                { path: "datepicker", element: <Datepicker />, },
                { path: "tom-select", element: <TomSelect />, },
                { path: "file-upload", element: <FileUpload />, },
                { path: "wysiwyg-editor", element: <WysiwygEditor />, },
                { path: "validation", element: <Validation />, },
                { path: "chart", element: <Chart />, },
                { path: "slider", element: <Slider />, },
                { path: "image-zoom", element: <ImageZoom />, },
            ],
        },
        {
            path: "/top-menu", element: <TopMenu />,
            children: [
                { path: "dashboard-overview-1", element: <DashboardOverview1 />, },
                { path: "dashboard-overview-2", element: <DashboardOverview2 />, },
                { path: "dashboard-overview-3", element: <DashboardOverview3 />, },
                { path: "dashboard-overview-4", element: <DashboardOverview4 />, },
                { path: "categories", element: <Categories />, },
                { path: "add-product", element: <AddProduct />, },
                { path: "product-list", element: <ProductList />, },
                { path: "product-grid", element: <ProductGrid />, },
                { path: "transaction-list", element: <TransactionList />, },
                { path: "transaction-detail", element: <TransactionDetail />, },
                { path: "seller-list", element: <SellerList />, },
                { path: "seller-detail", element: <SellerDetail />, },
                { path: "reviews", element: <Reviews />, },
                { path: "inbox", element: <Inbox />, },
                { path: "file-manager", element: <FileManager />, },
                { path: "point-of-sale", element: <PointOfSale />, },
                { path: "chat", element: <Chat />, },
                { path: "post", element: <Post />, },
                { path: "calendar", element: <Calendar />, },
                { path: "crud-data-list", element: <CrudDataList />, },
                { path: "crud-form", element: <CrudForm />, },
                { path: "users-layout-1", element: <UsersLayout1 />, },
                { path: "users-layout-2", element: <UsersLayout2 />, },
                { path: "users-layout-3", element: <UsersLayout3 />, },
                { path: "profile-overview-1", element: <ProfileOverview1 />, },
                { path: "profile-overview-2", element: <ProfileOverview2 />, },
                { path: "profile-overview-3", element: <ProfileOverview3 />, },
                { path: "wizard-layout-1", element: <WizardLayout1 />, },
                { path: "wizard-layout-2", element: <WizardLayout2 />, },
                { path: "wizard-layout-3", element: <WizardLayout3 />, },
                { path: "blog-layout-1", element: <BlogLayout1 />, },
                { path: "blog-layout-2", element: <BlogLayout2 />, },
                { path: "blog-layout-3", element: <BlogLayout3 />, },
                { path: "pricing-layout-1", element: <PricingLayout1 />, },
                { path: "pricing-layout-2", element: <PricingLayout2 />, },
                { path: "invoice-layout-1", element: <InvoiceLayout1 />, },
                { path: "invoice-layout-2", element: <InvoiceLayout2 />, },
                { path: "faq-layout-1", element: <FaqLayout1 />, },
                { path: "faq-layout-2", element: <FaqLayout2 />, },
                { path: "faq-layout-3", element: <FaqLayout3 />, },
                { path: "update-profile", element: <UpdateProfile />, },
                { path: "change-password", element: <ChangePassword />, },
                { path: "regular-table", element: <RegularTable />, },
                { path: "tabulator", element: <Tabulator />, },
                { path: "modal", element: <Modal />, },
                { path: "slideover", element: <Slideover />, },
                { path: "notification", element: <Notification />, },
                { path: "tab", element: <Tab />, },
                { path: "accordion", element: <Accordion />, },
                { path: "button", element: <Button />, },
                { path: "alert", element: <Alert />, },
                { path: "progress-bar", element: <ProgressBar />, },
                { path: "tooltip", element: <Tooltip />, },
                { path: "dropdown", element: <Dropdown />, },
                { path: "typography", element: <Typography />, },
                { path: "icon", element: <Icon />, },
                { path: "loading-icon", element: <LoadingIcon />, },
                { path: "regular-form", element: <RegularForm />, },
                { path: "datepicker", element: <Datepicker />, },
                { path: "tom-select", element: <TomSelect />, },
                { path: "file-upload", element: <FileUpload />, },
                { path: "wysiwyg-editor", element: <WysiwygEditor />, },
                { path: "validation", element: <Validation />, },
                { path: "chart", element: <Chart />, },
                { path: "slider", element: <Slider />, },
                { path: "image-zoom", element: <ImageZoom />, },
            ],
        },
        { path: "/login", element: <Login />, },
        { path: "/register", element: <Register />, },
        { path: "/error-page", element: <ErrorPage />, },
        { path: "*", element: <ErrorPage />, },
    ];

    return useRoutes(routes);
}

export default Router;