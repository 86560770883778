import { useState, Fragment } from "react";
import Lucide from "../../base-components/Lucide";
import Breadcrumb from "../../base-components/Breadcrumb";
import { FormInput } from "../../base-components/Form";
import { Menu, Popover } from "../../base-components/Headless";
import fakerData from "../../utils/faker";
import _ from "lodash";
import clsx from "clsx";
import { Transition } from "@headlessui/react";

import DarkModeSwitcher from "../../components/DarkModeSwitcher";
import MainColorSwitcher from "../../components/MainColorSwitcher";
import { useAppDispatch } from "../../stores/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../../stores/userSlice";

function Main() {
	const [searchDropdown, setSearchDropdown] = useState(false);
	const showSearchDropdown = () => { setSearchDropdown(true); };
	const hideSearchDropdown = () => { setSearchDropdown(false); };
	const [themeShow, setThemeShow] = useState<boolean>(false);
	function toggleThemeShow() { setThemeShow(!themeShow) }

	const dispatch = useAppDispatch();
	const nav = useNavigate()
	const confirmLogout = () => {
		dispatch(logout())
		nav('/auth')
	}

	return (
		<>
			<div className={themeShow ? "show" : "hidden"} >
				<DarkModeSwitcher />
				<MainColorSwitcher />
			</div>
			<div className="h-[67px] z-[51] flex items-center relative border-b border-slate-200">
				<Breadcrumb className="hidden mr-auto -intro-x sm:flex">
					<Breadcrumb.Link to="/">Application</Breadcrumb.Link>
					<Breadcrumb.Link to="/" active={true}>Dashboard</Breadcrumb.Link>
				</Breadcrumb>
				{/*<div className="relative mr-3 intro-x sm:mr-6">*/}
				{/*	<div className="relative hidden sm:block">*/}
				{/*		<FormInput type="text" className="border-transparent w-56 shadow-none rounded-full bg-slate-300/50 pr-8 transition-[width] duration-300 ease-in-out focus:border-transparent focus:w-72 dark:bg-darkmode-400/70"*/}
				{/*			placeholder="Search..." onFocus={showSearchDropdown} onBlur={hideSearchDropdown} />*/}
				{/*		<Lucide icon="Search" className="absolute inset-y-0 right-0 w-5 h-5 my-auto mr-3 text-slate-600 dark:text-slate-500" />*/}
				{/*	</div>*/}
				{/*	<a className="relative text-slate-600 sm:hidden" href="">*/}
				{/*		<Lucide icon="Search" className="w-5 h-5 dark:text-slate-500" />*/}
				{/*	</a>*/}
				{/*	<Transition as={Fragment} show={searchDropdown}*/}
				{/*		enter="transition-all ease-linear duration-150"*/}
				{/*		enterFrom="mt-5 invisible opacity-0 translate-y-1"*/}
				{/*		enterTo="mt-[3px] visible opacity-100 translate-y-0"*/}
				{/*		leave="transition-all ease-linear duration-150"*/}
				{/*		leaveFrom="mt-[3px] visible opacity-100 translate-y-0"*/}
				{/*		leaveTo="mt-5 invisible opacity-0 translate-y-1">*/}
				{/*		<div className="absolute right-0 z-10 mt-[3px]">*/}
				{/*			<div className="w-[450px] p-5 box">*/}
				{/*				<div className="mb-2 font-medium">Pages</div>*/}
				{/*				<div className="mb-5">*/}
				{/*					<a href="" className="flex items-center">*/}
				{/*						<div className="flex items-center justify-center w-8 h-8 rounded-full bg-success/20 dark:bg-success/10 text-success">*/}
				{/*							<Lucide icon="Inbox" className="w-4 h-4" />*/}
				{/*						</div>*/}
				{/*						<div className="ml-3">Mail Settings</div>*/}
				{/*					</a>*/}
				{/*					<a href="" className="flex items-center mt-2">*/}
				{/*						<div className="flex items-center justify-center w-8 h-8 rounded-full bg-pending/10 text-pending">*/}
				{/*							<Lucide icon="Users" className="w-4 h-4" />*/}
				{/*						</div>*/}
				{/*						<div className="ml-3">Users & Permissions</div>*/}
				{/*					</a>*/}
				{/*					<a href="" className="flex items-center mt-2">*/}
				{/*						<div className="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10 dark:bg-primary/20 text-primary/80">*/}
				{/*							<Lucide icon="CreditCard" className="w-4 h-4" />*/}
				{/*						</div>*/}
				{/*						<div className="ml-3">Transactions Report</div>*/}
				{/*					</a>*/}
				{/*				</div>*/}
				{/*				<div className="mb-2 font-medium">Users</div>*/}
				{/*				<div className="mb-5">*/}
				{/*					{_.take(fakerData, 4).map((faker, fakerKey) => (*/}
				{/*						<a key={fakerKey} href="" className="flex items-center mt-2">*/}
				{/*							<div className="w-8 h-8 image-fit">*/}
				{/*								<img alt="Catering Software" className="rounded-full" src={faker.photos[0]} />*/}
				{/*							</div>*/}
				{/*							<div className="ml-3">{faker.users[0].name}</div>*/}
				{/*							<div className="w-48 ml-auto text-xs text-right truncate text-slate-500">{faker.users[0].email}</div>*/}
				{/*						</a>*/}
				{/*					))}*/}
				{/*				</div>*/}
				{/*				<div className="mb-2 font-medium">Products</div>*/}
				{/*				{_.take(fakerData, 4).map((faker, fakerKey) => (*/}
				{/*					<a key={fakerKey} href="" className="flex items-center mt-2">*/}
				{/*						<div className="w-8 h-8 image-fit">*/}
				{/*							<img alt="Catering Software" className="rounded-full" src={faker.images[0]} />*/}
				{/*						</div>*/}
				{/*						<div className="ml-3">{faker.products[0].name}</div>*/}
				{/*						<div className="w-48 ml-auto text-xs text-right truncate text-slate-500">{faker.products[0].category}</div>*/}
				{/*					</a>*/}
				{/*				))}*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</Transition>*/}
				{/*</div>*/}
				{/*<Popover className="mr-auto intro-x sm:mr-6">*/}
				{/*	<Popover.Button className="relative text-slate-600 outline-none block before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:absolute before:top-[-2px] before:right-0 before:bg-danger">*/}
				{/*		<Lucide icon="Bell" className="w-5 h-5 dark:text-slate-500" />*/}
				{/*	</Popover.Button>*/}
				{/*	<Popover.Panel className="w-[280px] sm:w-[350px] p-5 mt-2">*/}
				{/*		<div className="mb-5 font-medium">Notifications</div>*/}
				{/*		{_.take(fakerData, 5).map((faker, fakerKey) => (*/}
				{/*			<div key={fakerKey} className={clsx(["cursor-pointer relative flex items-center", { "mt-5": fakerKey },])} >*/}
				{/*				<div className="relative flex-none w-12 h-12 mr-1 image-fit">*/}
				{/*					<img alt="Catering Software" className="rounded-full" src={faker.photos[0]} />*/}
				{/*					<div className="absolute bottom-0 right-0 w-3 h-3 border-2 border-white rounded-full bg-success dark:border-darkmode-600"></div>*/}
				{/*				</div>*/}
				{/*				<div className="ml-2 overflow-hidden">*/}
				{/*					<div className="flex items-center">*/}
				{/*						<a href="" className="mr-5 font-medium truncate"> {faker.users[0].name} </a>*/}
				{/*						<div className="ml-auto text-xs text-slate-400 whitespace-nowrap"> {faker.times[0]} </div>*/}
				{/*					</div>*/}
				{/*					<div className="w-full truncate text-slate-500 mt-0.5"> {faker.news[0].shortContent} </div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		))}*/}
				{/*	</Popover.Panel>*/}
				{/*</Popover>*/}
				<Menu>
					<Menu.Button className="block w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x">
						<img alt="Catering User Image" src={fakerData[9].photos[0]} />
					</Menu.Button>
					<Menu.Items className="w-56 mt-px text-white bg-primary">
						<Menu.Header className="font-normal">
							<div className="font-medium">{fakerData[0].users[0].name}</div>
							<div className="text-xs text-white/70 mt-0.5 dark:text-slate-500"> {fakerData[0].jobs[0]} </div>
						</Menu.Header>
						<Menu.Divider className="bg-white/[0.08]" />
						<Menu.Item className="hover:bg-white/5">
							<Lucide icon="User" className="w-4 h-4 mr-2" /> Profile
						</Menu.Item>
						{/*<Menu.Item className="hover:bg-white/5">*/}
						{/*	<Lucide icon="Edit" className="w-4 h-4 mr-2" /> Add Account*/}
						{/*</Menu.Item>*/}
						{/*<Menu.Item className="hover:bg-white/5">*/}
						{/*	<Lucide icon="Lock" className="w-4 h-4 mr-2" /> Reset Password*/}
						{/*</Menu.Item>*/}
						<Menu.Item className="hover:bg-white/5" onClick={toggleThemeShow}>
							<Lucide icon="Album" className="w-4 h-4 mr-2" />Change Theme</Menu.Item>
						{/*<Menu.Item className="hover:bg-white/5">*/}
						{/*	<Lucide icon="HelpCircle" className="w-4 h-4 mr-2" /> Help*/}
						{/*</Menu.Item>*/}
						<Menu.Divider className="bg-white/[0.08]" />
						<Menu.Item className="hover:bg-white/5" onClick={confirmLogout} >
							<Lucide icon="ToggleRight" className="w-4 h-4 mr-2" /> Logout
						</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</>
	);
}

export default Main;