import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as xlsx from "xlsx";
import '../../../../assets/css/tabulator-edit.css';
import Button from "../../../../base-components/Button";
import { FormInput, FormLabel } from "../../../../base-components/Form";
import { Dialog, Menu } from "../../../../base-components/Headless";
import { useLoader } from "../../../../base-components/Loader";
import Lucide from "../../../../base-components/Lucide";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Litepicker from "../../../../base-components/Litepicker";


// Extend the jsPDF interface to include the autoTable method
declare module 'jspdf' {
    interface jsPDF {
        autoTable: (options: any) => jsPDF;
    }
}
export default function Main() {
    

    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const tableRef = createRef<HTMLDivElement>();
    const tabulator = useRef<Tabulator>();
    const [id, setId] = useState(0);
    const sendButtonRef = useRef();
    const deleteButtonRef = useRef(null);
    const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
    const [successModalPreview, setSuccessModalPreview] = useState(false);
    const [modal, setModal] = useState({ msg: '', title: '', type: '' });
    const [totPages, setTotPages] = useState(0)
    const [customClass, setCustomClass] = useState('bg-green-500')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };

    useEffect(() => {//setSuccessModalPreview(true)
        initTabulator();
        reInitOnResizeWindow();
    }, []);
    const redrow = () => {
        if (tabulator.current) {
            tabulator.current.setData('/api/follow-up-list', { from: from, to: to })
            //console.log('redraw')
        }
    }
    const initTabulator = () => {
        if (tableRef.current) {
            tabulator.current = new Tabulator(tableRef.current, {//groupBy: "type",//data: dataList,
                ajaxFiltering: true,
                ajaxURL: "/api/follow-up-list", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    setTotPages(res.last_page)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",
                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "customerName", headerFilter: "input", headerFilterPlaceholder: "Search", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", headerSort: false },
                    { title: "Mobile", field: "mobile", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Registration Number", field: "registrationNumber", headerFilter: "input",  headerFilterPlaceholder: "Search", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Vehicle Details", field: "vehicleDetails", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    //{ title: "RequestServices", field: "requestServices", print: true, minWidth: 250, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    //{ title: "Complaint", field: "complaint", print: true, minWidth: 250, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Next Service Date", field: "nextServiceRequest", print: true,  hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                    { title: "Next Service KM", field: "nextServiceKM", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true, headerSort: false },
                  
                    //{
                    //    title: "Actions", print: false, formatter: deleteIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
                    //    //cellClick: function (_e, row) {const id = (row.getData() as any).id}
                    //}
                    //{
                    //    title: 'Edit', minWidth: 100,
                    //    formatter: () => '<i data-lucide="edit" class="w-8 h-8 mr-1 text-success"></i>',
                    //    cellClick: (e, cell) => {
                    //        const planData = cell.getRow().getData();

                    //        navigate(`/finance/new-service-entry/${planData.serviceRequestId}`);
                    //    },
                    //},
                    //{
                    //    title: 'Massage', headerSort: false,
                    //    formatter: () => `<button class="${customClass} hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Send Massage</button>`,
                    //	cellClick: (e, cell) => {
                    //		const planData = cell.getRow().getData();
                    //		//console.log(planData);
                    //        navigate(`/finance/complete-bill/${planData.serviceRequestId}`)
                    //	}
                    //},
                    //{
                    //    title: 'Delete', minWidth: 200, hozAlign: "center", headerHozAlign: 'center',
                    //    formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
                    //    cellClick: (e, cell) => {
                    //        const planData = cell.getRow().getData();
                    //        //console.log(planData.id)
                    //        setId(planData.serviceRequestId);
                    //        setDeleteConfirmationModal(true);
                    //    },
                    //},
                ],
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
   
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };

    const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
    const onExportPdf = () => {
        if (tabulator.current) {
            const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
            doc.autoTable({
                head: [['Sr.', 'Name', 'Mobile', 'Registration Number', 'Vehicle Details', 'Next Service Date','Next Service KM']],
                body: tabulator.current.getData().map(row => [row.srno, row.customerName, row.mobile, row.registrationNumber, row.vehicleDetails, row.nextServiceRequest, row.nextServiceKM]),
            });

            doc.save('FollowUpList.pdf')
        }
       // tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
    };
    const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "FollowUpList.csv"); };
    const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "FollowUpList.json"); };
    const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "FollowUpList.html", { style: true, }); };

    const onExportXlsx = () => {
        if (tabulator.current) {
            (window as any).XLSX = xlsx;
            tabulator.current.download("xlsx", "FollowUpList.xlsx", { sheetName: "Products", });
        }
    };

    return <>
        
        <div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Follow Up List</h2>
            <div className="flex w-full mt-4 sm:w-auto sm:mt-0">
                {/*<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/DeliveryPerson-Creation')} >Add Person</Button>   */}
            </div>
        </div>
        <div className="grid grid-cols-12 gap-4 gap-y-3">
            <div className="col-span-12 lg:col-span-2">
                <FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
                <div className="relative w-56 mx-auto">
                    <div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                        <Lucide icon="Calendar" className="w-4 h-4" />
                    </div>
                    <Litepicker
                        id="modal-datepicker-1"
                        value={from}
                        onChange={setFrom}
                        options={{
                            autoApply: false, format: 'DD/MM/YYYY',
                            showWeekNumbers: true,
                            dropdowns: {
                                minYear: 1990,
                                maxYear: null,
                                months: true,
                                years: true,
                            },
                        }}
                        className="pl-12" />
                </div>

            </div>
            <div className="col-span-12 lg:col-span-2">
                <FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
                <div className="relative w-56 mx-auto">
                    <div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                        <Lucide icon="Calendar" className="w-4 h-4" />
                    </div>
                    <Litepicker
                        id="modal-datepicker-2"
                        value={to}
                        onChange={setTo}
                        options={{
                            autoApply: false, format: 'DD/MM/YYYY',
                            showWeekNumbers: true,
                            dropdowns: {
                                minYear: 1990,
                                maxYear: null,
                                months: true,
                                years: true,
                            },
                        }}
                        className="pl-12" />
                </div>

            </div>


            <div className="col-span-12 lg:col-span-2 flex items-end ml-9">
                <Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>

            </div>
        </div>
        <div className="p-5 mt-5 intro-y box">
            <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <div className="xl:flex sm:mr-auto">
                    <Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
                    <Menu className="w-1/2 sm:w-auto">
                        <Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
                            <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
                            <Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
                        </Menu.Button>
                        <Menu.Items className="w-40">
                            <Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
                            <Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
                            <Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
                            <Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
                            <Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
                <Button onClick={() => {

                    if (tabulator.current) {
                        tabulator.current.setData()

                    }

                }}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
            </div>
            <div className="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref={tableRef} className="mt-5"></div>
            </div>
            <div className='flex w-full'>
                <span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
            </div>
        </div>
        
        
    </>
}